@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400&Dosis:wght@600&family=Lato:wght@300;400;700&family=Roboto&display=swap");
body {
  margin: 0;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--black);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  font-weight: 700 !important;
}

h3 {
  font-size: 16px !important;
  margin-bottom: 16px;
}

h5 {
  font-size: 12px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --gutterWidth: 5px;
  --fsXxs: 10px;
  --fsXs: 12px;
  --fss: 14px;
  --fsm: 16px;
  --fsl: 18px;
  --fsXl: 28px;
  --fsXxl: 32px;
  --fsJumbo: 36px;
  /* Font Weight */
  --fwLight: 300;
  --fwNormal: 400;
  --fwSemi-bold: 600;
  --fwBold: 700;
  /* Extra Colors other than bootstrap*/
  --black: #303335;
  --primaryColor: #ff4f4f;
  --lightGrey: #f1f3f3;
  --lightestGrey: #bdc0bf38;
  --shadow-01: 0px 0px 8px rgba(0, 0, 0, 0.08);

  --border-01: 1px solid var(--lightGrey);
  --border-02: 1px solid var(--primaryColor);

  --mono-3: #92999c;
  --mono-4: #737a7c;

  --transition: all 0.2s;
}
#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.flex-sa {
  justify-content: space-around;
}

.flex-sb {
  justify-content: space-between;
}

#root .btn {
  border-radius: 8px;
}

.height90 {
  min-height: 90vh;
}
.muted {
  color: #6c757d !important;
}
.center {
  text-align: center !important;
}
.white {
  color: #fff !important;
}
.flex {
  display: flex !important;
}
.m-five {
  margin: 3rem !important;
}
.nav_brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.nav_brand:focus,
.nav_brand:hover {
  text-decoration: none;
}
.mt-three {
  margin-top: 1rem !important;
}
.font_bold {
  font-weight: 700 !important;
}
.text_secondary {
  color: #6c757d !important;
}
