.linkContainer {
    /* background-color: #f5f5f7; */
    background-color: #d2d2d7;
    color: #6e6e73;
    /* border-bottom: 1px solid #d2d2d7; */
    padding: 10px;
}

.linkContainer a {
    font-size: x-small;
    color: #6e6e73;
    padding: 2px;
}

.linkContainer a:not(:last-child) {

    border-right: 1px solid #6e6e73;
}

.linkContainer a:hover {
    text-decoration: none;
}

.box {
    margin: 8px 4px;
}

.showmore {
    text-align: center;
    cursor: context-menu;
}

@media screen and (max-width: 800px) {
    .linkContainer a {
        font-size: xx-small;
    }
}