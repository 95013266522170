.card {
  text-decoration: none;
}
.card:hover {
  text-decoration: none;
}
.card :global .card-body {
  padding: 12px;
}

.modelName {
  font-family: Lato !important;
  font-style: normal;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 14px;
  color: #6e6e73;
  margin-bottom: 4px;
}

.card :global .h5 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 112.9%;
  color: #303335;
}

.card :global .card {
  /* border-radius: 12px; */
  /* box-shadow: var(--shadow-01); */
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  border: none;
}

.row {
  box-shadow: 0px 0px 8px rgb(0 0 0 / 8%);
  border-radius: 12px;
  border: none;
  max-height: 240px;
  cursor: pointer;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-decoration: none;
}
.row:hover {
  text-decoration: none;
}
.col-40 {
  width: 40%;
}

.col-60 {
  display: flex;
  flex-direction: column;
  width: 60%;
  padding: 0 15px;
  box-sizing: border-box;
}
.col-60 p {
  margin: 5px;
}
.price {
  color: #383335;
  font-family: "Lato";
  font-size: 10px;
  font-weight: bold;
  font-style: normal;
  line-height: 112.9%;
}
.name {
  font-family: "Lato" !important;
  line-height: 14px;
  font-style: normal;
  font-weight: normal !important;
  font-size: 12px;
  color: #6e6e73;
}
.blur {
  font-family: "Lato";
  color: #92999c !important;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
}
.anchorTag {
  text-decoration: none !important;
}
.anchorTag:hover {
  text-decoration: none !important;
}

@media only screen and (min-width: 768px) {
  .row {
    max-height: fit-content;
    position: relative;
    display: flex;
    flex-direction: column;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    align-items: unset;
    overflow: unset;
  }
  .col-40 {
    width: unset;
  }
  .col-60 {
    padding: 12px;
    width: unset;
  }
  .price {
    font-size: 14px;
  }
}
