.card {
  width: 100px;
  height: 100px;
  padding: 8px;
  /* border: var(--border-01); */
  border: 1px solid #f1f3f3;
  border-radius: 12px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-bottom: 8px;
  transition: var(--transition);
  cursor: pointer;
}

/* .card:active,
.card:focus {
  border: var(--border-02);
} */

.card .image img {
  object-fit: contain;
}

.card .image {
  width: 56px;
  max-height: 56px;
}

.card :global .h5 {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height */

  text-align: center;

  color: #6e6e73;
  margin-top: 12px;
  min-width: 30%;
}

@media screen and (min-width: 768px) {
  .card .image {
    width: 160px;
    max-height: 160px;
    margin: 12px;
  }

  .card {
    height: auto;
    width: 250px;
  }
}