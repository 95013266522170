@-webkit-keyframes flash {

  from,
  50%,
  to {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}

@keyframes flash {

  from,
  50%,
  to {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}

.loading {
  max-width: 120px;
  object-fit: contain;
  animation: flash 2s linear infinite;
}
