.name {
  margin-top: calc(var(--gutterWidth) * 8);
  font-size: var(--fsJumbo);
}

.heading {
  font-family: Lato !important;
  font-style: normal;
  font-weight: normal !important;
  color: #000000;
}

.name p {
  font-size: var(--fss);
}

.img_wrapper {
  margin-top: 60px;
}

.img {
  width: 100%;
  object-fit: contain;
  height: 400px;
  border-radius: 8px !important;
}

.filterBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter {
  border: 1px solid #f1f3f3;
  border-radius: 4px;
  padding: 0px 5px;
  display: flex;
  cursor: pointer;
  height: 30px;
}

.Icon {
  height: 40px;
}

.IconName {
  padding: 16px;
  display: flex;
  flex-direction: column;
}

.filterText {
  line-height: 30px;
  margin-bottom: 0px;
  vertical-align: middle;
  padding-left: 6px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #303335;
}

.filterButton {
  width: 160px;
}

.selected {
  background: #fff2f4;
  border: 1px solid #ff4f4f !important;
  box-sizing: border-box;
  border-radius: 12px;
}

.bodyType {
  padding: 10px;
  margin: 4px 3px;
  border: 1px solid #f1f3f3;
  border-radius: 12px;
  width: 120px;
  cursor: pointer;
}

.bodyname {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: #303335;
  margin-bottom: 0px;
}

.selectedTextBox {
  margin-right: 6px;
  padding: 6px 8px;
  border: 1px solid #ebf0f3;
  box-sizing: border-box;
  border-radius: 4px;
}

.selectedText {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  margin-bottom: 0px;
}

.filterHeading {
  display: flex;
  justify-content: space-between;
  padding: 20px 0px;
  box-sizing: border-box;
}

.selectedSpan {
  float: right;
  display: flex;
}

.typeName {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  padding: 6px 8px;
}

.jumbo {
  min-height: calc(100vh - 300px);
}

.title {
  padding: 12px 0 24px 0;
  font-size: 16px;
}

.filterTitle {
  padding: 50px 0;
  font-size: 16px;
}

.title_wrapper_fill {
  height: 24px;
}

.variantName {
  position: absolute;
  top: 16px;
  left: 0;
  z-index: 1;
  width: 100%;
  background: white;
  box-sizing: border-box;
  margin: 6px 0px;
  padding: 10px;
}

.variantFilter {
  margin-top: 10px;
  display: flex;
  justify-content: space-evenly;
}

.filterBodyBox {
  width: 50%;
  display: flex;
  justify-content: space-around;
}

.containerHeading {
  position: absolute;
  top: 16px;
  left: 0;
  z-index: 1;
  width: 100%;
  background: white;
  padding-bottom: 16px;
  padding-top: 32px;
  padding-left: 26px;
}

.closeBtn {
  position: absolute;
  right: 16px;
  top: 32px;
  background: none;
  border: none;
  z-index: 2;
}

.btn_wrapper {
  display: flex;
  justify-content: center;
  background: white;
  position: absolute;
  bottom: 0;
  padding: 20px 0px;
  width: 100%;
}

.selector_btn_wrapper {
  display: flex;
  justify-content: center;
  background: white;
  position: absolute;
  bottom: 0;
  padding-bottom: 40px;
  padding-top: 16px;
  width: 100%;
}

.selector_btn_wrapper_fill {
  height: 78px;
}

.info {
  margin: 30px 0px;
}

.metaInfo {
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding-bottom: 1em;
}

/* .metaInfo span {} */

.metaItems {
  display: flex;
  flex-wrap: wrap;
  padding: 30px 0px;
  justify-content: space-between;
}

.metaItems p {
  margin-bottom: 0px;
}

.metaItem {
  display: flex;
  flex-direction: row;
  padding: 8px 5px;
  align-items: center;
}

.metaTitle {
  font-size: 10px !important;
  color: #92999c;
  text-transform: uppercase;
  text-align: center;
  font-weight: 400;
  margin-bottom: 0px;
}
.loc {
  font-size: 12px !important;
  color: #ff4f4f;
  font-weight: 400;
  margin-bottom: 0px;
}

.colorName {
  color: black;
  height: 24px;
  margin-bottom: 0;
  text-align: center;
}

.metaContent {
  margin-top: 10px;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  color: #303335;
}

.price {
  font-size: 18px;
  font-weight: var(--fwSemi-bold) !important;
  position: relative;
  top: 5px;
  color: #303335;
}

.price span {
  font-size: var(--fsXl);
  position: relative;
  top: -6px;
}

.btn {
  font-size: small;
  width: 250px;
  height: 40px;
  background-color: #ff4f4f;
}

.blog {
  background-color: #484848;
  border-radius: 5px;
  padding: 10px;
  color: white;
  margin-bottom: 50px;
}

.blog .heading {
  color: white;
}

.blogContent {
  font-family: Lato;
  font-style: normal;
  font-weight: lighter;
  font-size: 16px;
}

.description {
  margin: 20px;
}

.descBox {
  padding: 10px;
  box-sizing: border-box;
}
.descBox img {
  max-width: 800px;
  width: 100%;
  display: block;
  margin: 30px auto;
}
.descBox p {
  margin: 0px;
}
.descBox a {
  font-size: large;
}

.modal {
  z-index: 3;
  display: block;
  padding-top: 100px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
}

.closebtn {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 0;
  color: #fff;
  font-size: 50px !important;
}

.gallerybtn {
  border: 1px solid #c5c5c7;
  padding: 4px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-family: Lato !important;
  font-style: normal;
  font-weight: normal !important;
  font-size: 14px;
}

.testdrive {
  color: #ff4f4f;
  border-color: #92999c;
  font-size: small;
  height: 40px;
  border: 1px solid #92999c;
  padding: 8px;
  border-radius: 8px;
  text-align: center;
  min-width: 200px;
  cursor: pointer;
}

.testdrive:hover {
  color: white;
  background-color: #ff4f4f;
  border-color: #ff4f4f;
  text-decoration: none;
}

.faq {
  margin: 30px auto;
}

.faqHeading {
  margin: 20px auto;
  text-align: center;
  font-size: 26px;
  font-family: Roboto !important;
  letter-spacing: 0.05em;
}

.faqButton {
  background-color: #ff4f4f;
  padding: 10px;
  border-radius: 6px;
  opacity: 0.7;
  color: white;
  margin: 10px auto 40px;
  display: block;
  text-align: center;
  width: 150px;
}

.faqButton:hover {
  text-decoration: none;
  opacity: 1;
  color: white;
}
.offers {
  padding: 20px 0px;
  margin: 20px auto;
  border-top: 1px dashed black;
  border-bottom: 1px dashed black;
}
.mobileView {
  display: none;
}
.buyingBTN {
  background-color: #ff4f4f;
  font-size: small;
  height: 40px;
  border: 1px solid #ff4f4f;
  color: white;
  padding: 8px;
  border-radius: 8px;
  text-align: center;
  min-width: 200px;
}

.buyingBTN:hover {
  color: white;
  background-color: white;
  color: #ff4f4f;
}
.selection {
  color: #92999c;
  margin: 0px;
  text-align: center;
}
.colorpicker {
  display: flex;
  justify-content: center;
  align-items: center;
}
.colorpicker img {
  height: 15px;
  margin-left: 5px;
}
.selelctColor {
  color: #ff4f4f;
}
.loginHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}
.loginHeading {
  text-align: center;
  width: 100%;
  margin-top: 10px;
}
@media screen and (min-width: 769px) {
  .name {
    position: absolute;
    bottom: 20%;
  }
  .buttonAlignment {
    display: none;
  }
  .mobileView {
    display: unset;
  }
  .desktopHide {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .buttonAlignment {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .buttonAlignment .testdrive {
    width: 100%;
    margin: 5px;
    min-width: unset;
  }

  .mobileHide {
    display: none;
  }
  .loc {
    text-align: center;
  }
  .heading {
    font-size: x-large;
  }
  .desktopHide {
    margin-top: 0px;
  }
  .metaContent {
    font-size: small;
  }

  .filterText {
    padding: 0px;
  }

  .btn {
    font-size: x-small;
    width: 100%;
  }

  .Icon {
    width: 40px;
  }
  .metaItem {
    width: 48%;
  }

  .metaTitle {
    text-align: left;
  }

  .filterBox {
    justify-content: center;
    flex-direction: column;
  }

  .testdrive {
    margin-top: 20px;
    width: 100%;
  }

  .price {
    text-align: left;
  }

  .filterText {
    /* display: none; */
    font-size: x-small;
  }

  .FilterIcon {
    width: 16px;
  }

  .blog .heading {
    font-size: x-large;
  }

  .modal {
    padding-top: 50%;
  }

  .faqHeading {
    font-size: large;
  }
}
