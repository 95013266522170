.search {
  width: 328px;
  margin-bottom: 20px;
}
.searchInputs {
  display: flex;
  background: #f1f3f3 !important;
  border-radius: 12px;
  margin: 10px;
  justify-content: space-between;
  height: 40px;
}

.search input {
  background-color: #f1f3f3;
  border: 0;
  margin-left: 10px;
  width: 100%;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #92999c;
}

.searchIcon {
  padding: 8px;
  box-sizing: border-box;
}
.testdriveIcon {
  height: 100%;
  box-sizing: border-box;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

input:focus {
  outline: none;
}
.searchIcon svg {
  font-size: 35px;
}

.dataResult {
  position: absolute;
  margin: 0px auto;
  z-index: 50;
  /* margin-top: 5px; */
  max-width: 328px;
  width: inherit;
  /* height: 200px; */
  border-radius: 5px;
  background-color: rgba(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow: hidden;
  overflow-y: auto;
}

.dataResult::-webkit-scrollbar {
  display: none;
}

.dataResult .dataItem {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  color: black;
  cursor: pointer;
  text-decoration: none;
}
.top {
  width: 40px;
  margin-left: 20px;
  margin-right: 20px;
}

.dataItem p {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  color: #92999c;
}

.dataItem:hover {
  background-color: lightgrey;
}

.clearBtn {
  cursor: pointer;
}
.logoimg {
  height: inherit;

  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
@media screen and (max-width: 800px) {
  .search {
    width: 100%;
  }
  .dataResult {
    width: 90%;
  }
  .testdrive {
    width: 80% !important;
  }
}
