.heading {
  margin: 30px auto;
  color: #6e6e73;
  font-family: Dosis, sans-serif !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 30px !important;
  text-align: center;
  position: relative;
}
@media screen and (min-width: 800px) {
  .heading:before {
    content: "";
    display: block;
    width: 25%;
    height: 2px;
    background: #6e6e73;
    left: 0;
    top: 50%;
    position: absolute;
    margin-left: 5%;
  }

  .heading:after {
    content: "";
    display: block;
    width: 25%;
    height: 2px;
    background: #6e6e73;
    right: 0;
    top: 50%;
    position: absolute;
    margin-right: 5%;
  }
}
