.brandContainer {
  padding: 16px;
}
.brands {
  padding: 16px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  text-decoration: none !important;
}
.brands :hover {
  text-decoration: none;
}
