.variant {
  margin-bottom: 16px;
  cursor: pointer;
  border: var(--border-01);
}
.variant_location {
  margin-bottom: 0.5rem !important;
  color: #6c757d !important;
}

.wrapper {
  min-height: 60vh;
}

.variant_name {
  font-weight: 400;
  font-size: var(--fss);
  color: var(--primaryColor);
  margin-bottom: 16px;
}

.variant_price {
  font-weight: 700;
  color: var(--black);
  float: right;
}

.variant__sub {
  font-size: var(--fss);
  font-weight: 400;
  color: var(--mono-4);
  text-transform: capitalize;
}
.variant__sub span {
  opacity: 0.5;
  width: 20px;
  display: inline-block;
  position: relative;
}
.variant__sub span:after {
  content: "";
  position: absolute;
  right: 10px;
  bottom: -5px;
  width: 1px;
  height: 18px;
  background: var(--black);
  opacity: 0.6;
}
.variant__sub__dark {
  color: var(--gray-dark);
}

.variantSelected {
  border-color: var(--primary);
}
