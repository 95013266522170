.checkout {
  padding-top: 24px;
}
.container {
  padding-bottom: 20px;
}
.font_bold {
  font-weight: bold;
}
.billing {
  padding: 16px;
  background: var(--lightGrey);
  border: var(--border-01);
  border-radius: 12px;
  margin-bottom: 8px;
}
.dealerContainer {
  display: flex;
  padding: 16px;
  background: var(--lightGrey);
  border: var(--border-01);
  border-radius: 12px;
  margin-bottom: 8px;
}
.dealer {
  padding-right: 40px;
  display: inline-block;
  max-height: 50px;
  /* max-width: 200px; */
  margin: 10px 0px;
}
.dealer img {
  height: 48px;
}
.billing p {
  display: flex;
  justify-content: space-between;
}

.billing span {
  font-weight: 700;
}
.bold {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #303335;
}

.title {
  padding: 12px 0 24px 0;
  font-size: 16px;
}

.title_wrapper_fill {
  height: 24px;
}

.title h3 {
  position: absolute;
  top: 16px;
  left: 0;
  z-index: 1;
  width: 100%;
  background: white;
  padding-bottom: 16px;
  padding-top: 32px;
  padding-left: 26px;
}

.closeBtn {
  position: absolute;
  right: 16px;
  top: 32px;
  background: none;
  border: none;
  z-index: 2;
}
.selector_btn_wrapper_fill {
  height: 78px;
}
.selector_btn_wrapper {
  display: flex;
  justify-content: center;
  background: white;
  position: absolute;
  bottom: 0;
  padding-bottom: 40px;
  padding-top: 16px;
  width: 100%;
}
.info {
  font-size: 14px;
}

.limiter {
  height: 1px;
  background: var(--mono-3);
  opacity: 0.3;
  margin-bottom: 8px;
}
@media screen and (max-width: 768px) {
  .dealerContainer {
    flex-direction: column;
  }
}
