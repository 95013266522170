.container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.slider {
    position: relative;
    width: 300px;
}

.slider__track,
.slider__range,
.slider__left_value,
.slider__right_value {
    position: absolute;
}

.slider__track,
.slider__range {
    border-radius: 3px;
    height: 5px;
}

.slider__track {
    background-color: #ced4da;
    width: 100%;
    z-index: 1;
}

.slider__range {
    background-color: #9fe5e1;
    z-index: 2;
}

.slider__left_value,
.slider__right_value {
    font-size: 12px;
    margin-top: 20px;
}

.slider__left_value {
    left: 6px;
}

.slider__right_value {
    right: -4px;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}

.thumb {
    pointer-events: none;
    position: absolute;
    height: 0;
    width: 300px;
    outline: none;
}

.thumb__zindex_3 {
    z-index: 3;
}

.thumb__zindex_4 {
    z-index: 4;
}

.thumb__zindex_5 {
    z-index: 5;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
    background-color: #f1f5f7;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
    background-color: #f1f5f7;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
}