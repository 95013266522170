.imageContainer {
  max-height: 800px;
  width: inherit;
  margin: 30px 0px;
}
.image {
  width: 100%;
  max-width: fit-content;
  height: inherit;
  display: block;
  margin: 0 auto;
}

.content {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  border-radius: 20px;
  background-color: #f1f3f3;
}
.heading {
  color: #6e6e73;
}
.blur {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #92999c;
}
.link {
  text-decoration: none !important;
}

@media screen and (max-width: 800px) {
  .heading {
    font-size: large;
  }
  .blur {
    font-size: 12px;
  }
  .content {
    width: 100%;
  }
}
