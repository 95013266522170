.feature {
  display: flex;
  margin: 30px auto;
}
.heading {
  font-family: Lato !important;
  font-style: normal;
  /* font-weight: normal !important; */
  color: #000000;
  font-size: larger;
  text-align: center;
  margin-bottom: 20px;
  text-decoration: underline;
}
.variantName {
  cursor: pointer;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin: 0px;
  padding: 10px;
  border: 1px solid #f1f3f3;
}
.variantName:hover {
  background-color: #fff2f4;
}
.selected {
  background: #fff2f4;
  border: 1px solid #ff4f4f;
}
.classification {
  cursor: pointer;
  margin-bottom: 0px;
  font-family: Lato !important;
  font-style: normal;
  font-size: x-large;
  font-weight: normal !important;
  color: #000000;
}
.variants {
  width: 30%;
  display: block;
  float: left;
  padding-right: 10px;
}
.featureDescription {
  width: 70%;
  display: block;
  float: right;
}
.firsthalf {
  display: inline-block;
  width: 50%;
}
.secondhalf {
  display: inline-block;
  width: 50%;
}
.featureContent {
  display: flex;
}
.mobile {
  display: none;
}
.amount {
  float: right;
}
@media screen and (max-width: 1020px) {
  .variants {
    /* display: none; */
    width: 100%;
    margin-bottom: 20px;
  }
  .featureDescription {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .feature {
    flex-direction: column;
  }
  .mobile {
    display: unset;
  }

  .mobile:global.dropdown {
    margin: 0 auto;
    display: block;
    text-align: center;
    margin-bottom: 20px;
  }
  .mobile :global .btn {
    width: 100%;
  }
  .mobile :global .dropdown-menu {
    width: 100%;
    border: 1px solid #3f3e3d;
    height: 300px;
    overflow: auto;
  }
  .mobile :global .dropdown-item:active {
    background-color: unset;
  }
  .mobile :global .dropdown-item:hover {
    background-color: unset;
    cursor: default;
  }
  .mobile :global .dropdown-toggle {
    justify-content: space-between;
    display: flex;
    align-items: center;
  }
  .featureDescription {
    width: unset;
    float: unset;
  }
  .variantName {
    /* border-radius: 12px; */
    font-size: 10px;
    margin: 4px 0px;
    padding: 6px;
  }
  .classification {
    font-size: medium;
  }
  .featureContent {
    font-size: small;
  }
}
