.component {
  display: flex;
  padding: 8px;
  border: var(--border-01);
  margin-bottom: 24px;
  border-radius: 12px;
}

.image {
  flex-basis: 36%;
  display: flex;
  flex-direction: column;
}
.bold {
  font-family: Lato !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  color: #303335;
}
.variantEdit {
  display: flex;
}
.variantEdit img {
  margin-left: 10px;
  border: 1px solid #8ea0a0;
  border-radius: 50%;
}
.variantEdit img:hover {
  background-color: #f1f3f3;
  border-radius: 50%;
}
.variantEdit p {
  display: flex;
  align-items: center;
  margin-bottom: 0px !important;
}
.content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-left: 16px;
}

.content h3 {
  margin-top: 3px;
}

.image img {
  object-fit: contain;
  border-radius: 8px;
}

@media screen and (max-width: 768px) {
  .bold {
    font-size: 12px !important;
  }
  .content p {
    margin-bottom: 5px;
    font-size: x-small;
  }
}
