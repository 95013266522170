.cont {
  margin: 50px auto;
}
.img {
  width: 100%;
  object-fit: contain;
  height: 400px;
  border-radius: 8px !important;
}
.cont_left {
  margin-top: 30%;
}
.showroom {
  text-align: center;
  font-size: 20px;
  color: #00afa0;
  margin-bottom: 20px;
}
.price {
  font-size: var(--fsl);
  font-weight: var(--fwSemi-bold) !important;
  position: relative;
  top: 5px;
}

.price span {
  font-size: var(--fsXl);
  position: relative;
  top: -6px;
}
.dealer {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dealerLogo {
  max-width: 100px;
  margin-right: 10px;
  display: inline-block;
  min-height: 50px;
}
.dealerLogo img {
  width: 100%;
}
.heading {
  font-family: Lato !important;
  font-style: normal;
  font-weight: normal !important;
  color: #000000;
  margin-top: 10px;
  text-align: center;
}
.exchangeContainer {
  border: 1px solid #ced4da;
  padding: 10px;
  border-radius: 10px;
  margin: 30px auto;
}
.exHeading {
  font-size: 24px !important;
  color: #495057;
  text-align: center;
  margin: 10px;
}
.h4 {
  text-align: center;
  margin: 20px auto;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #ff4f4f;
}

.clearBtn {
  float: right;
  padding: 10px;
  margin-right: 10px;
}
.closeContainer {
  width: 100%;
}
.loginScreen {
  display: flex;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  background-color: #24272cb3;
  /* background-color: #fff; */
  /* opacity: 0.95; */
  flex-direction: column;
  width: 100%;
  height: 100%;
  z-index: 999999999;
  color: #000000;
  transform: translate(-50%, -50%);
}
.shade {
  background-color: white;
  width: 90%;
  max-width: 600px;
  margin: 5%;
  border-radius: 5px;
}
.modal {
  z-index: 1000000;
  display: block;
  padding-top: 100px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}
.locIcon {
  cursor: pointer;
}
.btn {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 0;
  color: #fff;
  font-size: 50px !important;
}

.content {
  cursor: default;
  margin: auto;
  background-color: #fff;
  text-align: center;
  position: relative;
  color: #ff4f4f;
  min-height: 50%;
  border-radius: 10px;
  padding: 20px;
  outline: 0;
  width: 600px;
  animation: animatezoom 0.6s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.close {
  background-color: #ff4f4f;
  color: #fff;
  display: block;
  max-width: 100px;
  border-radius: 5px;
  padding: 5px 10px;
}

@media screen and (max-width: 768px) {
  .modal {
    padding-top: 50px;
  }
  .content {
    width: auto;
    margin: 50px 20px;
  }
  .cont_left {
    margin-top: 20px;
  }
  .dealer {
    flex-direction: column;
  }
  .price {
    text-align: center;
  }
  .heading {
    font-size: xx-large;
  }
}
