.navbar {
  /* margin-top: 20px; */
  padding: 10px;
  background: #f5f5f7;
}

.footer_bottom {
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.footer_top {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 6px;
  text-align: center;
}

.navbar a {
  text-decoration: none;
  color: #515154;
  font-size: x-small;
}

.navbar p {
  margin-bottom: 0px;
  color: #515154;
  font-size: x-small;
}

.icon {
  width: 20px;
  height: auto;
  margin: 0px 5px;
}

@media screen and (max-width: 768px) {
  .navbar {
    font-size: x-small !important;
  }
}