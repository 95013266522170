.swatch {
  border-radius: 10px;
  height: 40px;
  width: 40px;
  margin: 8px;
  border: 1px solid var(--lightGrey);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: var(--transition);
}

.swatch img {
  width: 100%;
}
.selection {
  color: #92999c;
  margin: 0px;
  text-align: center;
}
.colorpicker {
  display: flex;
  justify-content: center;
  align-items: center;
}
.colorpicker img {
  height: 15px;
  margin-left: 5px;
}
.colors {
  display: flex;
  justify-content: center;
  /* padding-top: 1em; */
  flex-wrap: wrap;
}

.swatchSelected {
  border: 1px solid var(--primary);
}

.colorName {
  color: var(--mono-3);
}
