.offerContainer {
  width: 90%;
  margin: 30px auto;
}
.cont {
  position: sticky;
  top: 0;
  padding-top: 60px;
}
.card {
  border: 1px solid #e2e5e7;
  border-radius: 12px;
}
.cardText {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cardCallback {
  padding: 12px 24px;
}
.cardModel {
  font-family: Lato;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  color: #5a6164;
  margin-bottom: 0px;
}
.cardVaraint {
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  color: #303335;
  margin-bottom: 0px;
}
.offerContainer.loading {
  position: relative;
}
.loadingSVG {
  max-height: 80%;
}
.offerContainer.loading::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255);
}
.loadingText {
  font-size: 24px;
}
.loadingScreen {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  /* background-color: #24272cb3; */
  background-color: #fff;
  /* opacity: 0.95; */
  flex-direction: column;
  width: 100%;
  height: 100%;
  z-index: 999999999;
  color: #000000;
  transform: translate(-50%, -50%);
}
.dealer {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f1f3f3;
  padding: 20px;
}
.Pricedealer {
  margin-top: 20px;
  border-top: 1px dashed #cdd2d4;
}
.logos {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dealerLogo {
  max-width: 100px;
  margin-right: 10px;
  display: inline-block;
  min-height: 50px;
}
.dealerLogo img {
  width: 100%;
}
.heading {
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  color: #5a6164;
}
.cont_left {
  overflow-y: auto;
  margin-top: 60px;
}
.startAmount {
  font-family: Lato;
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
}
.showroom {
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  color: #303335;
  margin-top: 10px;
}
.address {
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  color: #5a6164;
}
.price {
  font-family: Lato;
  font-size: 12px;
  font-weight: 500;
  color: #303335;
  margin-bottom: 0px;
}
.congrats,
.details {
  font-family: Lato;
  font-size: 12px;
  font-weight: 400;
  color: #303335;
}
.down_payment_starts {
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  color: #303335;
}

.img {
  width: 100%;
  object-fit: contain;
  height: inherit;
  border-radius: 8px !important;
}

.h4 {
  margin: 20px auto;
  font-family: Lato;
  font-size: 20px;
  font-weight: 400;
  font-size: 14px;
  color: #ff4f4f;
}
.offers {
  overflow: hidden;
  padding: 20px;
  border-bottom: 1px solid #e2e5e7;
}
.offer_heading {
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  color: #303335;
}
.offer_text {
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  color: #303335;
  margin-bottom: 0px;
  margin-left: 10px;
}
.offerTitle {
  display: flex;
}
.offerPrice {
  font-family: Lato;
  font-size: 22px;
  font-weight: 700;
  color: #ff4f4f;
  margin-bottom: 0px;
}
.offer {
  display: flex;
  flex-direction: column;
  border: 1px solid #e2e5e7;
  border-radius: 8px;
  min-width: 50%;
  margin: 10px 5px;
  padding: 5px 10px;
  background-color: #fff;
  color: #24272cb3;
}

.paddingContainer {
  padding-left: 35px;
}
.offerText {
  color: #5a6164;
  font-family: Lato;
  font-size: 14px;
  font-weight: 500;
}
.financeLead {
  color: #ff4f4f;
  margin-bottom: 0px;
  margin-top: 20px;
  cursor: pointer;
  text-align: center;
}
.financeText {
  color: #5a6164;
  font-family: Lato;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0px;
}
.offerList {
  padding: 35px;
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  scrollbar-width: none;
}

.offerList::-webkit-scrollbar {
  display: none;
}

.scrollContainer {
  display: flex;
  align-items: center;
  position: relative;
  cursor: grab;
}

.scrollButton {
  width: 48px;
  height: 48px;
  -webkit-box-shadow: 0 8px 12px 0 rgb(36 39 44 / 15%);
  box-shadow: 0 8px 12px 0 rgb(36 39 44 / 15%);
  position: absolute;
  top: 50%;
  margin-top: -24px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  background: #fff;
  opacity: 0.3;
  /* background: #f56900; */
  z-index: 10;
  text-indent: -9999px;
  right: -27px;
  left: auto;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.scrollButtonNext {
  right: -27px;
  left: auto;
}

.scrollButtonPrev {
  left: -27px;
}

.scrollButton::after {
  border: 1px solid #000000;
  border-width: 0 0 2px 2px;
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  line-height: 0;
  font-size: 0;
  display: block;
  left: 50%;
  top: 50%;
  margin: -4px 0 0 -2px;
}

.scrollButtonPrev::after {
  -webkit-transform: rotate(-315deg);
  transform: rotate(-315deg);
}

.scrollButtonNext::after {
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
  margin: -4px 0 0 -6px;
}

.scrolling-wrapper {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding: 16px 0;
  scroll-behavior: smooth;
  scrollbar-width: none;
}

.scrolling-wrapper::-webkit-scrollbar {
  display: none;
}

.name {
  /* color: #93ff89; */
  color: #303335;
  border-bottom: 1px solid #e2e5e7;
  padding-bottom: 5px;
}
.description {
  font-family: Lato;
  font-size: 14px;
  font-weight: 500;
  color: #5a6164;
}
.condition {
  font-size: x-small;
  text-align: right;
  color: #0288d1;
  margin-top: auto;
}
.offerDisplayButton {
  font-family: Lato;
  font-size: 14px;
  font-weight: 500;
  margin-right: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.offerButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.financeForm {
  margin: 20px auto;
}
.flex {
  display: flex;
  align-items: center;
}
.flex .details {
  margin-right: 10px;
  margin-bottom: 0px;
}
.flex .down_payment_starts {
  margin-right: 10px;
  margin-bottom: 0px;
}
.amount {
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  color: #ff4f4f;
}
.varaintContainer {
  box-shadow: 0px 4px 18px 0px #00000040;
  border-radius: 6px;
  padding: 16px;
}
.closebtn {
  display: flex;
  flex-direction: row-reverse;
  cursor: pointer;
}
.qstn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.qstn p {
  margin-bottom: 5px;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  color: #303335;
}
.variantDetails {
  color: #737a7c;
}
.faqcard {
  margin: 0px 10px;
}

.price_amount {
  float: right;
  font-weight: bold;
}
.variantFilter {
  display: flex;
  /* justify-content: space-evenly; */
  border-bottom: 1px solid #f1f3f3;
}
.selected {
  /* background: #fff2f4; */
  border-bottom: 5px solid #ff4f4f !important;
  box-sizing: border-box;
  padding-bottom: 5px !important;
  /* border-radius: 12px; */
}
.selectedModel {
  background: #fff2f4;
  border: 1px solid #ff4f4f !important;
  box-sizing: border-box;
}
.bodyType {
  padding: 10px;
  /* margin: 4px 3px; */
  /* border: 1px solid #f1f3f3; */
  /* border-radius: 12px; */
  width: 120px;
  cursor: pointer;
}
.offerBackground {
  background-color: #f1f3f3;
}
.offerBackground .offers {
  border-top-left-radius: 30px;
  background-color: white;
}
.varaintList {
  padding: 10px;
  overflow-x: auto;
  max-height: 500px;
  scroll-behavior: smooth;
  scrollbar-width: none;
}

.varaintList::-webkit-scrollbar {
  display: none;
}
.variant {
  padding: 10px;
  border: 1px solid #f1f3f3;
  border-radius: 8px;
  margin-bottom: 5px;
  cursor: pointer;
}
.selectedVariant {
  border: 1px solid #e2e5e7;
  background-color: #f1f3f3;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.selectedVariant p,
.selectedVarianth3 {
  margin-bottom: 0px;
}
.selectedVariant p {
  font-family: Lato;
  font-size: 10px;
  font-weight: 400;
  color: #5a6164;
}
.selectedVariant h3 {
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  color: #303335;
}
.priceContainer {
  border: 1px solid #e2e5e7;
  margin: 10px auto;
  border-radius: 8px;
  padding: 16px;
}
.bodyname {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: #303335;
  margin-bottom: 0px;
}
.callbackButtonBottom {
  margin-top: 16px;
  width: 100%;
  text-align: center;
}
.callbackButtonBottom .cardCallback {
  width: 40%;
}
.border {
  border-top: 1px dashed #cdd2d4;
  padding-top: 10px;
}
.login {
  margin: 16px;
  color: #ff4f4f;
  cursor: pointer;
  display: inline-block;
}
.callbackButton {
  background-color: #fff;
  border: 1px solid #92999c;
  color: #000000;
}

.loadingAnm {
  max-width: 500px;
  max-height: 500px;
}
.onRoadPrice {
  font-size: large;
}
.clearBtn {
  float: right;
  padding: 10px;
  margin-right: 10px;
}
.closeContainer {
  width: 100%;
}
.loginScreen {
  display: flex;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  background-color: #24272cb3;
  /* background-color: #fff; */
  /* opacity: 0.95; */
  flex-direction: column;
  width: 100%;
  height: 100%;
  z-index: 999999999;
  color: #000000;
  transform: translate(-50%, -50%);
}
.shade {
  background-color: white;
  width: 90%;
  max-width: 600px;
  margin: 5%;
  border-radius: 5px;
}
.modal {
  z-index: 1000000;
  display: block;
  padding-top: 100px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}
.locIcon {
  cursor: pointer;
}
.btn {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 0;
  color: #fff;
  font-size: 50px !important;
}
.notRealtive {
  position: unset;
}
.content {
  cursor: default;
  margin: auto;
  background-color: #fff;
  text-align: center;
  position: relative;
  color: #ff4f4f;
  min-height: 50%;
  border-radius: 10px;
  padding: 20px;
  outline: 0;
  width: 600px;
  animation: animatezoom 0.6s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.close {
  background-color: #ff4f4f;
  color: #fff;
  display: block;
  max-width: 100px;
  border-radius: 5px;
  padding: 5px 10px;
}
.callbackButtonBottom .bottomButtom {
  display: none;
}
.loginHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}
.loginHeading {
  text-align: center;
  width: 100%;
  margin-top: 10px;
}
.label {
  background-color: #284166;
  font-size: 12px;
  color: #fff;
  padding: 4px 8px;
  margin-bottom: 10px;
  border-radius: 3px;
  max-width: 100%;
  display: inline-block;
}
.deliveryTime {
  background-color: #006e33;
  color: #fff;
  padding: 5px 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 3px;
  max-width: 100%;
  display: inline-block;
}
@media screen and (max-width: 768px) {
  .cont {
    padding-top: 0px;
  }
  .cardText {
    display: none;
  }
  .callbackButtonBottom {
    position: sticky;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 10px;
  }
  .callbackButtonBottom .bottomButtom {
    display: block;
    margin-bottom: 0px;
  }
  .callbackButtonBottom .cardCallback {
    width: 50%;
  }
  .varaintContainer {
    height: 100%;
    width: 100%;
    z-index: 9999999999999999999999;
    overflow: auto;
    background-color: #fff;
    position: fixed;
    left: 0;
    top: 0;
  }
  .name {
    font-size: large;
  }
  .description {
    font-weight: 600 !important;
  }
  .offer {
    min-width: 80%;
    font-size: medium;
  }
  .cont_left {
    margin-top: 20px;
  }
  .condition {
    font-size: xx-small;
  }
  .dealer {
    flex-direction: column;
  }
  .loadingSVG {
    max-width: 90%;
  }
  .loadingText {
    font-size: small;
  }
  .modal {
    padding-top: 50px;
  }
  .content {
    width: auto;
    margin: 50px 20px;
  }
  .flex {
    flex-direction: column;
    align-items: normal;
  }
}
