.imgWrapper {
  padding-top: 66%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img {
  padding-top: 66%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.imageWithoutStyles {
  padding-top: 66%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.imageWithoutStyles img {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  /* object-fit: contain; */
  object-fit: cover;
  border-radius: 4px;
}
.imgWrapper img {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px 12px 0px 0px;
}
.img img {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.imgWrapper :global .react-loading-skeleton {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px 12px 0px 0px;
}
