.search {
  padding: 16px;
}

.models {
  padding: 0 8px;
}

.card {
  margin-bottom: 2em;
  padding: 0 8px;
}
.Heading {
  text-align: center;
  padding: 10px;
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
}
.BrandLogo {
  width: 80px;
  margin-right: 20px;
}
.BrandLogo img {
  width: auto !important;
}
.brandName {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  font-family: Lato !important;
  font-style: normal;
}
.searchButton {
  display: flex;
  background-color: #ff4f4f;
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 130px;
  right: 50px;
  border-radius: 50% !important;
  box-sizing: border-box;
}
@media screen and (max-width: 768px) {
  .brandName {
    font-size: larger;
  }
  .searchButton {
    right: 20px;
  }
}
@media screen and (min-width: 768px) {
  .models {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  .card {
    width: 33%;
  }
}
