.navbar {
  padding: 0 10px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ff4f4f;
}

.nav-right img {
  margin: 0 5px;
}

.logo {
  display: flex;
  align-items: center;
  height: 26px;
  justify-content: space-around;
}

.logo img {
  height: 100%;
}

.navcontent {
  padding: 5px;
  /* margin: 5px; */
  color: white !important;
  border-radius: 4px;
}

.navcontent:hover {
  background-color: RGB(255, 105, 105);
}

.brand {
  font-family: Dosis !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 18px;
  text-transform: uppercase;
  color: #303335;
  line-height: 23px;
  margin-bottom: 0px;
}

.navbar a {
  text-decoration: none;
  letter-spacing: 0.05em;
}

.searchIcon {
  padding: 8px;
  box-sizing: border-box;
}

.headerSearch {
  cursor: pointer;
}

.searchBar {
  width: 100%;
  display: flex;
  /* background: #f1f3f3 !important; */
  border-radius: 12px;
  justify-content: space-between;
  height: 40px;
}

.searchBar input {
  /* background-color: #f1f3f3; */
  border: 0;
  /* margin-left: 10px; */
  width: 100%;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #303335;
}

.searchResult {
  text-decoration: none;
  color: #92999c;
  display: flex;
  height: 50px;
  align-items: center;
  cursor: pointer;
}

.searchResult:hover {
  text-decoration: none;
  color: #303335;
}

.searchResult p {
  margin-bottom: 0px;
}

.top {
  width: 40px;
  /* margin-left: 20px; */
  margin-right: 20px;
}

.modalClass :global .modal-content {
  /* min-height: 90vh; */
  height: 100% !important;
}

.modalClass :global .modal-header {
  align-items: center;
  padding: 0;
  border-bottom: none;
}

.modalClass :global .modal-body {
  border-top: 1px solid #dee2e6;
}

.clearBtn {
  margin: 0;
  padding: 8px;
  cursor: pointer;
}

@media screen and (min-width: 1300px) {
  .modalClass :global .modal-content {
    /* min-height: 90vh; */
    width: 50% !important;
    text-align: center;
    margin: auto;
  }
}
