$blue: #6995dd;
$red: #ff4f4f;
$primary: $red;
$info: #9fbae1;
$dark: #37131d;
$gray: #737a7c;
$body-color: #303335;

.card-img-top {
  min-height: 200px;
}

@import "~bootstrap/scss/bootstrap.scss";

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
