.carouselcontainer:global.carousel {
  max-height: 350px !important;
  /* background-color: #74797c; */
}

.carouselcontainer :global .carousel-inner {
  max-height: inherit;
}

.carouselcontainer :global .carousel-item {
  max-height: inherit;
}

.carouselcontainer .imagewrapper {
  max-height: 350px !important;
}

.carouselcontainer :global .carousel-control-prev,
.carouselcontainer :global .carousel-control-next {
  max-height: 350px;
}

.carouselcontainer :global .carousel-indicators li {
  width: 8px;
  height: 1px;
  border: none;
}

.container :global .carousel-item {
  text-align: center;
}

@media screen and (max-width: 767px) {
  .carouselcontainer:global.carousel {
    max-height: 150px !important;
  }

  .carouselcontainer .imagewrapper {
    max-height: 150px !important;
  }

  .carouselcontainer :global .carousel-control-prev,
  .carouselcontainer :global .carousel-control-next {
    max-height: 150px !important;
  }

  .container .imagewrapper {
    width: 100%;
  }
}

@media screen and (min-width: 767px) and (max-width: 900px) {
  .carouselcontainer:global.carousel {
    max-height: 180px !important;
  }

  .carouselcontainer .imagewrapper {
    max-height: 180px !important;
  }

  .carouselcontainer :global .carousel-control-prev,
  .carouselcontainer :global .carousel-control-next {
    max-height: 180px !important;
  }

  .container .imagewrapper {
    width: 100%;
  }
}