.search {
  padding: 16px;
}
.heading {
  font-family: Lato !important;
  font-style: normal;
  font-weight: normal !important;
  color: #000000;
  padding: 6px 8px;
}
.models {
  padding: 0 8px;
}

.card {
  padding: 16px 8px;
}
.containerSearch {
  padding-top: 15px;
  padding-bottom: 15px;
}
.Skelton {
  margin: 0px auto;
}
.searchButton {
  display: flex;
  background-color: #ff4f4f;
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 130px;
  right: 50px;
  border-radius: 50% !important;
  box-sizing: border-box;
}
@media screen and (max-width: 768px) {
  .searchButton {
    right: 20px;
  }
}
@media screen and (min-width: 768px) {
  .models {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  .card {
    width: 33%;
  }
}
