.login {
  max-width: 500px;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
}

.error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
.btn {
  margin-bottom: 25px;
  margin-top: 25px;
  text-align: center;
}
