.background {
  width: 100%;
  height: 400px;
  background-image: linear-gradient(
    to right top,
    #051937,
    #004d7a,
    #008793,
    #00bf72,
    #a8eb12
  );
  margin: 20px auto;
  border-radius: 16px;
}

.content {
  position: absolute;
  background-color: white;
  color: #6e6e73;
  border-radius: 16px;
  height: 300px;
  width: 60%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.review {
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 30px;
}
.line {
  color: blue;
  font-size: 36px;
}
.line:before {
  content: "";
  display: block;
  width: 40%;
  height: 2px;
  background: #6e6e73;
  left: 0;
  top: 20%;
  position: absolute;
  margin-left: 5%;
}
.line:after {
  content: "";
  display: block;
  width: 40%;
  height: 2px;
  background: #6e6e73;
  right: 0;
  top: 20%;
  position: absolute;
  margin-right: 5%;
}
.star {
  color: gold;
  font-size: 16px;
}

.location {
  font-size: 14px;
  margin: 0px;
}

@media screen and (max-width: 800px) {
  .content {
    width: 75%;
  }
  .review {
    font-size: 10px;
  }
  .location {
    font-size: 10px;
  }
}
