.plusMinus {
  font-weight: 900;
  font-size: larger;
  margin-left: 16px;
}
.qstn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
