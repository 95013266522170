.modal {
  z-index: 1000000;
  display: block;
  padding-top: 100px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}
.locIcon {
  cursor: pointer;
}
.btn {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 0;
  color: #fff;
  font-size: 50px !important;
}

.content {
  cursor: default;
  margin: auto;
  background-color: #fff;
  text-align: center;
  position: relative;
  color: #ff4f4f;
  min-height: 50%;
  border-radius: 10px;
  padding: 20px;
  outline: 0;
  width: 600px;
  animation: animatezoom 0.6s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.close {
  background-color: #ff4f4f;
  color: #fff;
  display: block;
  max-width: 100px;
  border-radius: 5px;
  padding: 5px 10px;
}

@media only screen and (max-width: 768px) {
  .modal {
    padding-top: 50px;
  }
  .content {
    width: auto;
    margin: 50px 20px;
  }
}
