.home {
  padding: 16px;
}

.models {
  padding: 0 8px;
}

.topCarList {
  display: flex;
}

.topcarbody {
  margin: 0 10px;
  padding: 5px 10px;
  cursor: pointer;
  letter-spacing: 0.05em;
}

.selectedTopcar {
  border-bottom: 3px solid #ff4f4f;
}

.scrollContainer {
  display: flex;
  align-items: center;
  position: relative;
}

.scrollButton {
  width: 48px;
  height: 48px;
  -webkit-box-shadow: 0 8px 12px 0 rgb(36 39 44 / 15%);
  box-shadow: 0 8px 12px 0 rgb(36 39 44 / 15%);
  position: absolute;
  top: 50%;
  margin-top: -24px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  background: #1a73e8;
  /* background: #f56900; */
  z-index: 10;
  text-indent: -9999px;
  right: -27px;
  left: auto;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.scrollButtonNext {
  right: -27px;
  left: auto;
}

.scrollButtonPrev {
  left: -27px;
}

.scrollButton::after {
  border: 1px solid #fff;
  border-width: 0 0 2px 2px;
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  line-height: 0;
  font-size: 0;
  display: block;
  left: 50%;
  top: 50%;
  margin: -4px 0 0 -2px;
}

.scrollButtonPrev::after {
  -webkit-transform: rotate(-315deg);
  transform: rotate(-315deg);
}

.scrollButtonNext::after {
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
  margin: -4px 0 0 -6px;
}

.scrolling-wrapper {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding: 16px 0;
  scroll-behavior: smooth;
  scrollbar-width: none;
}

.scrolling-wrapper::-webkit-scrollbar {
  display: none;
}

.card {
  width: 200px;
  flex: 0 0 auto;
  margin-right: 12px;
  padding: 0 8px;
}

.testdrive {
  height: 150px;
  width: 80%;
  margin: 20px auto;
  display: flex;
  background-color: #fff;
  border: 1px solid #ff4f4f;
  border-radius: 8px;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
}

.testdriveimg {
  max-height: 150px;
}

.testdriveheading {
  text-align: center;
  font-family: Roboto !important;
  font-size: 20px !important;
  font-weight: normal !important;
  color: #6e6e73;
  letter-spacing: 0.02em;
}

.brands {
  padding: 16px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  text-decoration: none !important;
}

.brands :hover {
  text-decoration: none;
}

.brand {
  margin: 8px;
}

.bodyType {
  padding: 10px;
  margin-bottom: 8px;
  border: 1px solid #ff4f4f;
  border-radius: 12px;
  width: 350px;
  cursor: pointer;
}

.bodyType:hover {
  background-color: #fff2f4;
}

.bodyBox {
  padding: 16px 0px;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.bodyname {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: #6e6e73;
  margin-bottom: 0px;
}

.priceType {
  margin-bottom: 8px;
  padding: 10px;
  border: 1px solid #ff4f4f;
  border-radius: 8px;
  width: 150px;
  cursor: pointer;
}

.priceType:hover {
  background-color: #fff2f4;

}

.selected {
  background: #fff2f4;
  border: 1px solid #ff4f4f;
  box-sizing: border-box;
  border-radius: 12px;
}

.selectedSpan {
  float: right;
  display: flex;
}

.selectedTextBox {
  margin-right: 6px;
  padding: 6px 8px;
  border: 1px solid #ebf0f3;
  box-sizing: border-box;
  border-radius: 4px;
}

.selectedText {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  margin-bottom: 0px;
}

.priceName {
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #6e6e73;
  margin-bottom: 0px;
}

.searchButton {
  position: relative;
  margin-bottom: 5%;
  margin-top: 5%;
  left: 50%;
  margin-left: -80px;
  border-radius: 8px;
  width: 160px;
  box-sizing: border-box;
}

.typeName {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.type {
  width: 80px;
  height: 80px;
}

/* .modalClass :global .modal-content {
  min-height: 90vh;
  height: 100% !important;
} */
.modalClass :global .modal-header {
  align-items: center;
  padding: 10px;
}

.accordian :global .card {
  border: 0;
}

.modalClass :global .modal-body {
  padding: 0;
}

.modalClass :global .modal-body .accordion {
  min-height: 85vh;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.accordian :global .card-header {
  background-color: #ffffff;
  border-bottom: 1px solid #f1f3f3;
  padding: 10px 0px;
  cursor: pointer;
}

.accordian {
  padding: 10px;
}

.clearBtn {
  cursor: pointer;
}

.mousePointer {
  cursor: pointer;
}

.filter {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 0px;
  color: #000000;
}

.contentBox{
  margin: 30px auto;
  color: #6e6e73;
  border: 1px solid #ff4f4f;
  border-radius: 8px;
  padding: 10px;
}
.contentBox h1 ,.contentBox h2{
  display: inline-block;
  margin: auto;
  border-bottom: 1px solid black;}
.contentBox p{
  margin-bottom: 0px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.contentBox .heading{
  text-align: center;
}
.faqBox{
  margin-top: 20px;
}

@media screen and (min-width: 1300px) {
  .home {
    width: 1300px !important;
    max-width: 1300px;
  }
}

@media screen and (max-width: 800px) {
  .scrollButton {
    width: 30px;
    height: 30px;
  }

  .scrollButtonNext {
    right: -12px;
    left: auto;
  }

  .scrollButtonPrev {
    left: -12px;
  }

  .heading {
    text-align: center !important;
  }

  .bodyType {
    width: 100px;
  }

  .type {
    height: 40px;
  }

  .priceType {
    width: auto;
  }

  .testdrive {
    width: 100%;
    height: 120px;
  }

  .testdriveimg {
    display: none;
  }

  .topCarList {
    justify-content: center;
  }
}