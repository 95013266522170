.confirmation {
  margin: 5% auto;
  padding: 16px;
  background: var(--lightGrey);
  border: var(--border-01);
  border-radius: 12px;
}
.model {
  margin: 5% auto;
  padding: 16px;
  border: var(--border-01);
  border-radius: 12px;
}
.details span {
  width: 70%;
  float: right;
}
.confirmbtn {
  display: block;
  margin: 0 auto;
}
.image {
  margin: 20px 0px;
  max-width: 300px;
}
.content {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
@media screen and (max-width: 768px) {
  .details span {
    width: unset;
    float: unset;
  }
  .image {
    margin: 20px auto;
  }
}
