.selection {
  margin-top: 30%;
}

.heading {
  font-family: Lato !important;
  font-style: normal;
  font-weight: normal !important;
  color: #000000;
  margin-top: 10px;
  text-align: center;
}

.location {
  display: flex;
  justify-content: space-evenly;
  margin: 10px 0px;
}

.h4 {
  text-align: center;
  margin: 20px auto;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #303335;
}

.locationbtn {
  padding: 5px;
  margin: 4px 3px;
  border: 1px solid #aaa;
  border-radius: 12px;
  width: 120px;
  cursor: pointer;
  text-align: center;
}

.testdrive {
  margin: 50px auto;
}

.description {
  padding: 20px 0px;
}

.mainheading {
  text-align: center;
  margin: 20px auto 0px;
  font-family: Lato !important;
  font-style: normal;
  font-weight: normal !important;
  color: #555;
}

.datepick {
  width: 180px;
  height: 40px;
  display: block;
  margin: 20px auto;
}

.datepick input {
  cursor: pointer;
  padding: 5px;
  text-align: center;
  border-radius: 8px;
  border: 1px solid #aaa;
}
.labelDiv {
  display: flex;
  justify-content: center;
}
.label {
  background-color: #284166;
  font-size: 12px;
  color: #fff;
  padding: 4px 8px;
  margin-bottom: 10px;
  border-radius: 3px;
  max-width: 100%;
  display: inline-block;
}
.selectbtn {
  display: block;
  margin: 0 auto;
}

.features {
  margin: 50px auto;
}

.faq {
  margin: 30px auto;
}

.faqHeading {
  margin: 20px auto;
  text-align: center;
  font-size: 26px;
  font-family: Roboto !important;
  letter-spacing: 0.05em;
}

@media screen and (max-width: 768px) {
  .selection {
    margin-top: 30px;
  }

  .faqHeading {
    font-size: large;
  }

  .heading {
    font-size: xx-large;
  }
}
